<template>
  <st-side-page class="others-page">
    <portal to="page-name">Configurações</portal>
    <template slot="side">
      <ul class="menu">
        <li class="menu-item" v-for="(menu, i) in menus" :key="i">
          <router-link :to="menu.path">{{ menu.label }}</router-link>
          <fa-icon :icon="['fal', 'chevron-right']"/>
        </li>
      </ul>
    </template>
    <router-view/>
  </st-side-page>
</template>

<script>
export default {
  data() {
    return {
      menus: [
        { path: '/clinical/insurances', label: 'Convênios' },
        { path: '/clinical/professionals', label: 'Profissionais corpo clínico' },
        { path: '/clinical/template-main', label: 'Modelos de impressos' },
        { path: '/clinical/procedures', label: 'Serviços, Taxas e Produtos' },
        { path: '/clinical/schedules', label: 'Gerenciamento de agendas' },
        { path: '/forwards', label: 'Encaminhamentos', icon: 'table' },
        { path: '/phone-books', label: 'Agenda telefônica' },
        // { path: '/marketing/campaigns', label: 'Marketing e relacionamento' },
        { path: '/clinical/indicators', label: 'Indicadores' },
        { path: '/clinical/reports', label: 'Relatórios' },
      ],
    };
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="scss">
  @import "./src/assets/scss/_variables.scss";
  .others-page {
    &.side-page {
      .side-page-side {
        flex: 0 0 11rem;
      }
    }
    .menu {
      .router-link-exact-active {
        color: $primary-color;
        & + svg {
          color: $primary-color;
        }
      }
    }
  }
</style>
